<template>
  <v-dialog
    v-model="view.toggle"
    scrollable
    transition="scroll-y-transition"
    :max-width="size"
  >
    <v-card 
      class="sheet"
    >
      <v-card-title>
        <span class="">Enviar Comunicado</span>
        <v-spacer />
        <v-switch
          v-model="input.alert.value"
          inset
          :label="input.alert.label"
        />
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="view.valid"
          class="messenger-form py-4"
        >
          <v-text-field
            v-model="input.subject.value"
            :loading="input.subject.loading"
            :disabled="input.subject.disabled"
            :error-messages="input.subject.messages"
            :label="input.subject.label"
            clearable
            outlined
            class=""
          />
          <v-autocomplete
            v-model="input.recipients.value"
            :loading="input.recipients.loading"
            :disabled="input.recipients.disabled"
            :error-messages="input.recipients.messages"
            :label="input.recipients.label"
            :items="to"
            item-value="email"
            item-text="name"
            chips
            multiple
            deletable-chips
            hide-no-data
            outlined
            class=""
          />
          <v-text-field
            v-model="input.title.value"
            :loading="input.title.loading"
            :disabled="input.title.disabled"
            :error-messages="input.title.messages"
            :label="input.title.label"
            clearable
            outlined
            class=""
          />
          <v-textarea
            v-model="input.message.value"
            :loading="input.message.loading"
            :disabled="input.message.disabled"
            :success="input.message.success"
            :label="input.message.label"
            :hint="input.message.hint"
            height="240px"
            persistent-hint
            outlined
          ></v-textarea>

          <!-- <span class="text-overline text-disabled my-4">REF: {{ reference }}</span> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="grey"
          class="btn-cancel"
          @click="view.toggle = false;"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          type="submit"
          :disabled="!view.valid"
          :loading="view.loading"
          text
          color="primary"
          class="btn-send"
          @click="sendCommunication"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

  .messenger .v-select__selections {
    max-height: 120px;
    overflow-y: scroll;
    border: 2px solid transparent;
  }

</style>

<script>

  // Icons
  import {
    mdiClose,
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'Messenger',

    props: {
      toggle: {
        type: Boolean,
        default: false
      },
      to: {
        type: Array,
        default: () => []
      },
      subject: {
        type: String,
        default: null
      },
      message: {
        type: String,
        default: null
      },
      reference: {
        type: String,
        default: null
      },
      user: {
        type: String,
        default: ''
      },
      token: {
        type: String,
        default: ''
      },
    },

    data: () => ({
      icons: {
        close: mdiClose,
      },
      view: {
        toggle: false,
        loading: false,
        valid: false,
      },
      input: {
        alert: {
          value: false,
          label: 'Alerta app',
          loading: false,
          disabled: false,
        },
        subject: {
          value: '',
          loading: false,
          disabled: false,
          label: 'Assunto/Título Notificação',
          success: false,
          rules: [v => v!='' || 'Campo obrigatório'],
        },
        recipients: {
          value: [],
          sent: [],
          loading: false,
          disabled: false,
          label: 'Destinatários',
          success: false,
          rules: [v => v.length>0 || 'Selecione os destinatários'],
        },
        title: {
          value: 'Fala motora, {{NOME}}! Beleza?',
          loading: false,
          disabled: false,
          label: 'Header Email',
          success: false,
          rules: [v => v!='' || 'Campo obrigatório'],
        },
        message: {
          value: '',
          loading: false,
          disabled: false,
          label: 'Mensagem',
          success: false,
          hint: 'Formatação: utlize {{NOME}}, {{SOBRENOME}} ou {{EMAIL}} para inserir dados dinâmicos dos destinatários.',
          rules: [v => v!='' || 'Campo obrigatório'],
        },
      },
    }),

    computed: {
      toast: sync('app/toast'),

      size () {
        return this.breakpoint(null, 'xs') ? '100vw' : this.breakpoint(null, 'sm') ? '60vw' : '32rem'
      },
    },

    watch: {
      toggle (v) {
        console.log(v)
        this.view.toggle = v;
        this.input.subject.value = this.subject;
        this.input.message.value = this.message;
      },
      to (v) {
        this.input.recipients.value = _.map(v, r => r.email);
      },
      'view.toggle' (b) {
        if (!b) this.$emit('toggle-messenger', b);
      }

    },

    methods: {
      ...services,

      formData () {
        const user = this.user;
        const buzzer = this.buzzer;
        const name = user.profile.fullname;
        const from = `${name} <${user.profile.email}>`;
        const partner = user.partner || user.status=='ACT';
        const vehicle = user.profile.vehicle.model + ' ' + user.profile.vehicle.year;
        const faq = !!this.item ? this.item.title : '–';

        let body = '';
        body += 'Data: '+moment().format('DD/MM HH:mm');
        body += '\n' + (partner ? 'Parceiro' : 'Motorista') + ': '+name;
        body += '\n';
        body += '\nMensagem: '+this.fields.message.value;
        body += '\n';
        body += '\nContexto: '+this.context;
        body += '\nTema Ajuda: '+this.fields.section.value;
        body += '\nItem Ajuda: '+ faq;
        body += '\nCategoria: '+this.fields.category.value;
        body += '\n';
        body += '\nCPF: ' + this.rawCPF(user.cpf);
        body += '\nEmail: '+user.profile.email;
        body += '\nCelular: '+user.profile.phone;
        body += '\nVeículo: '+vehicle;
        body += '\n';
        body += '\nVersão do app: '+user.profile.app_version;
        body += '\nDevice: '+user.profile.os;
        body += '\n';
        if (partner) {
          body += '\n<b>Buzzer:</b> Status: ' + buzzer.status + ' | COD: ' + user.profile.buzzer.code + ' | NR Patrimônio: ' + user.profile.buzzer.number + ' | ID: ' + user.profile.buzzer.id;
          // body += '<br><b>Horas no ar:</b> Total ' + buzzer.status + ' | Fechamento ' + buzzer.code + ' | NR Patrimônio: ' + buzzer.number + ' | ID: ' + buzzer.id;
        }

        return {
          useradmin: this.user,
          authTk: this.token,
          assunto: 'Atendimento – '+this.fields.category.value,
          mensagem: body,
          // to: 'Suporte Mobees <suporte@mobees.com.br>',
          // from: from,
        }
      },

      processField (value, recipient) {
        if (!_.isNil(value)) {
          let name = recipient.name.split(' ');
          if (_.isString(name)) name = [name,name];
          value = value.replaceAll('{{NOME}}', name[0]);
          value = value.replaceAll('{{SOBRENOME}}', name[1]);
          value = value.replaceAll('{{EMAIL}}', recipient.email);
          value = value.replaceAll('\n', '<br>');
        }
        return value;
      },

      sendCommunication () {
        const recipients = this.input.recipients;
        const recipient = _.find(this.to, ['email', _.first(_.difference(recipients.value, recipients.sent))]);
        if (recipients.value.length==recipients.sent.length) {
            recipients.value = []
            recipients.sent = []
            this.toggleToast(
              true,
              'Comunicados enviado com sucesso!',
              7000,
              false,
            );
        }
        if (_.isNil(recipient)) {
          this.view.toggle = false;
          return false;
        }
        this.view.loading = true;

        const data = {
          useradmin: this.user,
          authTk: this.token,
          assunto: this.processField(this.input.subject.value, recipient),
          tipo: this.processField(this.input.title.value, recipient),
          mensagem: this.processField(this.input.message.value, recipient),
          types: _.join(['email', this.input.alert.value ? 'alert' : 'push'], ','),
          reference: this.reference,
          to: recipient.email,
          cpf: recipient.cpf,
          from: 'Mobees <suporte@mobees.com.br>'
        };

        console.log(data);

        this.$api.COM({
          url: '/sendcommunication',
            method: 'POST',
            data: data
          })
          .then(response => {
            console.log('sendCommunication => ',response);
            if(response.data.retorno==200){
              recipients.sent.push(recipient.email);

              this.toggleToast(
                true,
                `Enviando comunicados (${recipients.sent.length}/${recipients.value.length})...`,
                -1,
                false,
              );


            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível enviar o comunicado. Tente novamente por favor.',
                5000,
                false,
              );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.view.loading = false;
            this.sendCommunication();
          });
      },
    },

    mounted () {
      this.view.toggle = this.toggle;
    }
  }
</script>
